import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import globe from '../images/globe.svg'

import './404.scss'

const NotFoundPage = () => (
  <Layout background="#31363A">
    <div className="not-found">
      <SEO title="404: Not found" />
      <h1>Oh... This is embarassing. The page wasn't found.</h1>
      <p>While you're here, look at this mesmerizing globe.</p>
      <img className="not-found__img" src={globe} alt="Globe" />
    </div>
  </Layout>
)

export default NotFoundPage
